import React, { useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'

import { dataStore } from '../store/dataStore'
import URLSearchParams from '@ungap/url-search-params'
import { setUtm } from '../utils/utm'
import { WhatInItForYou } from '../components/WhatInItForYou'

import { DEBUG } from '../constant'
import ImageHeaderDonatello from '../components/header/ImageHeaderDonatello'
import ClinicsMap from '../components/ClinicsMap'
import ProcessOfSigningUp from '../components/ProcessOfSigningUp'
import { FactsAboutTheStudy } from '../components/FactsAboutTheStudy'

import { WhoAreWeLookingFor } from '../components/WhoAreWeLookingFor'
import { BottomDrawerSheet } from '../components/drawer/BottomDrawerSheet'

const IndexPage = (props) => {
  // should give the same output as the useTranslation hook
  // const language = props.data.locales.edges[0].node.language

  const location = props.location
  //const { t, i18n } = useTranslation()

  const hasQuery = location.search
  setUtm(hasQuery)

  const getLocation = dataStore((state) => state.getLocation)
  const [showDrawer, setShowDrawer] = useState(false)

  const readMoreRef = useRef()
  const signupRef = useRef()

  useEffect(() => {
    const queryString = new URLSearchParams(location.search)

    const testIp = queryString.get('testip') || ''

    //getLocation(testIp)
  }, [getLocation, location.search])

  const openDrawer = () => {
    setShowDrawer(true)
  }

  const closeDrawer = (value) => {
    setShowDrawer(value)
  }

  const scrollToSignup = (event) => {
    ///window.scrollTo({ behavior: 'smooth', top: designRef.current.offsetTop })
    signupRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  // if (!DEBUG) {
  //   return (
  //     <div className="container-fluid">
  //       <div className="row" style={{}}>
  //         <div className="col-12">
  //           <h1>Coming soon</h1>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <Layout location={location} title={'Strides'}>
      <Seo title="Are you struggling with knee pain? Join the DONATELLO study" />

      <ImageHeaderDonatello
        readMoreRef={readMoreRef}
        signupRef={signupRef}
        openDrawer={openDrawer}
      ></ImageHeaderDonatello>

      <div ref={readMoreRef} className="container-fluid my-6">
        <WhatInItForYou scrollToSignup={scrollToSignup}></WhatInItForYou>
      </div>

      <div className="container-fluid " style={{ background: '#ECF1E5' }}>
        <WhoAreWeLookingFor signupRef={signupRef}></WhoAreWeLookingFor>
      </div>

      <div className="container-fluid" style={{ background: '#FAFAFA' }}>
        <div className="container pt-7" style={{ maxWidth: '1000px' }}>
          <ClinicsMap scrollToSignup={scrollToSignup}></ClinicsMap>
        </div>
      </div>

      <div className="container-fluid">
        <ProcessOfSigningUp scrollToSignup={scrollToSignup}></ProcessOfSigningUp>
      </div>

      <FactsAboutTheStudy scrollToSignup={scrollToSignup}></FactsAboutTheStudy>

      <BottomDrawerSheet openDrawer={showDrawer} closeFunc={closeDrawer}></BottomDrawerSheet>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
