/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 16/04/2021.
 */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './image-header.scss'
import { useMediaQuery } from 'react-responsive'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { sendToDataLayer } from '../../utils/DataLayer'
import { PROJECT_REF } from '../../constant'
import { Button } from '../../components/Button'

const ImageHeaderDonatello = ({ openDrawer, readMoreRef, signupRef }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isTablet = useMediaQuery({ maxWidth: 991 })
  //
  // we are using two different formhooks for mobile and desktop
  //

  const handleScrollToElement = (event) => {
    ///window.scrollTo({ behavior: 'smooth', top: designRef.current.offsetTop })
    sendToDataLayer({ event: 'READ_MORE_HEADER', projectRef: PROJECT_REF })
    readMoreRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const gotoSignup = () => {
    console.log('ImageHeaderDonatello > toSignup is mobile = ', isMobile)
    if (isMobile) {
      openDrawer()
    } else {
      signupRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const minHeight = (isMobile || isTablet) ? "450px" : "700px";

  return (
    <div className="d-flex image-header position-relative mx-auto overflow-hidden" style={{ minHeight: minHeight }}>
      <div className="container-fluid h-100 px-0 position-absolute  " style={{ zIndex: -10 }}>
        <div className="row h-100 mx-auto img-row ">
          <div className="col-12 h-100 d-flex justify-content-center px-0 mx-auto ">
            <StaticImage
              className="d-none d-lg-block"
              objectFit="scale-down"
              src="../../images/hero.png"
              objectPosition="66% 0%"
              alt="studiesAndMe"
              quality={100}
              placeholder="none"
            ></StaticImage>

            <StaticImage
              className="d-block d-lg-none w-100"
              objectFit=""
              src="../../images/hero_mobile.png"
              objectPosition="0% 0%"
              alt="studiesAndMe"
              quality={100}
              placeholder="none"
            ></StaticImage>
          </div>
        </div>
      </div>

      <div className="container-fluid h-100">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 px-0 col-md-8 col-lg-5 h-100 d-flex align-items-center">
              <div className="header-text head-color">
                <p className="text-center text-md-start px-5 px-md-0">{t('header.headlineTop')}</p>
                <h4 className="text-center text-md-start mt-4 mb-5 " style={{ lineHeight: '130%' }}>
                  <Trans i18nKey="header.headline"></Trans>
                </h4>

                {/*<h1
                  className="text-center fw-bold text-white d-block  d-lg-none  display-3 mt-4 mb-5 hero-txt"
                  style={{ lineHeight: '150%', letterSpacing: '.08rem', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)' }}
                >
                  <Trans i18nKey="header.headline"></Trans>
                </h1>*/}

                <p className="text-center text-md-start pe-md-6 head-color">{t('header.body')}</p>
                <div className="mt-5 d-flex flex-md-row flex-column align-content-center align-items-start px-4 px-md-0">
                  {/*<button onClick={openDrawer} className="d-block d-md-none btn btn-primary mb-3 mb-md-0 ">
                    {t('header.signupButton')}
                  </button>*/}

                  <Button
                    onClick={gotoSignup}
                    type="primary"
                    className="mb-3 d-block py-3 d-md-none w-100"
                    label={'header.signupButton'}
                  ></Button>
                  <Button
                    onClick={handleScrollToElement}
                    label={'header.readMoreButton'}
                    className="btn-shadow d-md-none py-3 d-block w-100"
                  ></Button>

                  <Button
                    onClick={gotoSignup}
                    type="primary"
                    className="me-3 d-md-block d-none py-2"
                    label={'header.signupButton'}
                  ></Button>
                  <Button
                    onClick={handleScrollToElement}
                    label={'header.readMoreButton'}
                    className="btn-shadow d-md-block d-none py-2"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageHeaderDonatello
//CarlsBergVej21742635##
