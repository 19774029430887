/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/06/2023.
 */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Button } from './Button'

const stateNumArr = ['1', '2', '3', '4', '5', '6', '7']
const ClinicsMap = ({ scrollToSignup }) => {
  const { t } = useTranslation()

  const clickker = () => {
    scrollToSignup()
  }

  // new
  return (
    <>
      <div className="row mt-2 mt-md-7">
        <div className="col-12">
          <div className="row" style={{}}>
            <div className="col-12 d-flex justify-content-center mb-3">
              <h4 className="text-center text-md-start">{t('joinTheStudyAtYourConvenience.headline')}</h4>
            </div>
            <div className="col-12 d-flex justify-content-center mb-5">
              <p>{t('joinTheStudyAtYourConvenience.subHeadline')}</p>
            </div>
          </div>
        </div>

        <div className="col-12 d-sm-none px-0 mb-2">
          <div className="rounded-5 bg-white border d-flex align-items-center px-3 py-4">
            <StaticImage
                className=""
                style={{ width: 60, height: 60 }}
                objectFit=""
                src="../images/map-small.png"
                objectPosition="0% 0%"
                alt="studiesAndMe"
                quality={100}
                placeholder="none"
            ></StaticImage>

            <div className="ps-4 mb-0 h-100 d-flex flex-column justify-content-center">
              <h4 className="fw-bold" style={{ fontSize: '1.5rem' }}>
                {t('joinTheStudyAtYourConvenience.city7')}, {t('joinTheStudyAtYourConvenience.zipCode7')}
              </h4>
              <p className="">{t('joinTheStudyAtYourConvenience.states7')}</p>
            </div>
          </div>
        </div>

        <div className="col-12 d-sm-none px-0 mb-2">
          <div className="rounded-5 bg-white border d-flex align-items-center px-3 py-4">
            <StaticImage
                className=""
                style={{ width: 65, height: 65 }}
                objectFit=""
                src="../images/map-small.png"
                objectPosition="0% 0%"
                alt="studiesAndMe"
                quality={100}
                placeholder="none"
            ></StaticImage>

            <div className="ps-4 mb-0 h-100 d-flex flex-column justify-content-center">
              <h4 className="fw-bold" style={{ fontSize: '1.5rem' }}>
                {t('joinTheStudyAtYourConvenience.city4')}, {t('joinTheStudyAtYourConvenience.zipCode4')}
              </h4>
              <p className="">{t('joinTheStudyAtYourConvenience.states4')}</p>
            </div>
          </div>
        </div>

        <div className="col-12 d-sm-none px-0 mb-2">
          <div className="rounded-5 bg-white border d-flex align-items-center px-3 py-4">
            <StaticImage
                className=""
                style={{ width: 65, height: 65 }}
                objectFit=""
                src="../images/map-small.png"
                objectPosition="0% 0%"
                alt="studiesAndMe"
                quality={100}
                placeholder="none"
            ></StaticImage>

            <div className="ps-4 mb-0 h-100 d-flex flex-column justify-content-center">
              <h4 className="fw-bold" style={{ fontSize: '1.5rem' }}>
                {t('joinTheStudyAtYourConvenience.city5')}, {t('joinTheStudyAtYourConvenience.zipCode5')}
              </h4>
              <p className="">{t('joinTheStudyAtYourConvenience.states4')}</p>
            </div>
          </div>
        </div>

        <div className="col-12 d-sm-none px-0 mb-2">
          <div className="rounded-5 bg-white border d-flex align-items-center px-3 py-4">
            <StaticImage
                className=""
                style={{ width: 60, height: 60 }}
                objectFit=""
                src="../images/map-small.png"
                objectPosition="0% 0%"
                alt="studiesAndMe"
                quality={100}
                placeholder="none"
            ></StaticImage>

            <div className="ps-4 mb-0 h-100 d-flex flex-column justify-content-center">
              <h4 className="fw-bold" style={{ fontSize: '1.5rem' }}>
                {t('joinTheStudyAtYourConvenience.city6')}, {t('joinTheStudyAtYourConvenience.zipCode6')}
              </h4>
              <p className="">{t('joinTheStudyAtYourConvenience.states5')}</p>
            </div>
          </div>
        </div>

        <div className="col-12 d-sm-none px-0 mb-2">
          <div className="rounded-5 bg-white border d-flex align-items-center px-3 py-4">
            <StaticImage
                className=""
                style={{ width: 60, height: 60 }}
                objectFit=""
                src="../images/map-small.png"
                objectPosition="0% 0%"
                alt="studiesAndMe"
                quality={100}
                placeholder="none"
            ></StaticImage>

            <div className="ps-4 mb-0 h-100 d-flex flex-column justify-content-center">
              <h4 className="fw-bold" style={{ fontSize: '1.5rem' }}>
                {t('joinTheStudyAtYourConvenience.city9')}, {t('joinTheStudyAtYourConvenience.zipCode9')}
              </h4>
              <p className="">{t('joinTheStudyAtYourConvenience.states5')}</p>
            </div>
          </div>
        </div>

        <div className="col-12 d-sm-none px-0 mb-2">
          <div className="rounded-5 bg-white border d-flex align-items-center px-3 py-4">
            <StaticImage
                className=""
                style={{ width: 60, height: 60 }}
                objectFit=""
                src="../images/map-small.png"
                objectPosition="0% 0%"
                alt="studiesAndMe"
                quality={100}
                placeholder="none"
            ></StaticImage>

            <div className="ps-4 mb-0 h-100 d-flex flex-column justify-content-center">
              <h4 className="fw-bold" style={{ fontSize: '1.5rem' }}>
                {t('joinTheStudyAtYourConvenience.city3')}, {t('joinTheStudyAtYourConvenience.zipCode3')}
              </h4>
              <p className="">{t('joinTheStudyAtYourConvenience.states3')}</p>
            </div>
          </div>
        </div>

        <div className="col-12 d-sm-none px-0 mb-2">
          <div className="rounded-5 bg-white border d-flex align-items-center px-3 py-4">
            <StaticImage
              className=""
              style={{ width: 65, height: 65 }}
              objectFit=""
              src="../images/map-small.png"
              objectPosition="0% 0%"
              alt="studiesAndMe"
              quality={100}
              placeholder="none"
            ></StaticImage>

            <div className="ps-4 mb-0 h-100 d-flex flex-column justify-content-center">
              <h4 className="fw-bold" style={{ fontSize: '1.5rem' }}>
                {t('joinTheStudyAtYourConvenience.city8')}, {t('joinTheStudyAtYourConvenience.zipCode8')}
              </h4>
              <p className="">{t('joinTheStudyAtYourConvenience.states6')}</p>
            </div>
          </div>
        </div>

        <div className="col-12 d-sm-none px-0 mb-2">
          <div className="rounded-5 bg-white border d-flex align-items-center px-3 py-4">
            <StaticImage
              className=""
              style={{ width: 60, height: 60 }}
              objectFit=""
              src="../images/map-small.png"
              objectPosition="0% 0%"
              alt="studiesAndMe"
              quality={100}
              placeholder="none"
            ></StaticImage>

            <div className="ps-4 mb-0 h-100 d-flex flex-column justify-content-center">
              <h4 className="fw-bold" style={{ fontSize: '1.5rem' }}>
                {t('joinTheStudyAtYourConvenience.city2')}, {t('joinTheStudyAtYourConvenience.zipCode2')}
              </h4>
              <p className="">{t('joinTheStudyAtYourConvenience.states2')}</p>
            </div>
          </div>
        </div>

        <div className="col-12 d-sm-none px-0 mb-2">
          <div className="rounded-5 bg-white border d-flex align-items-center px-3 py-4">
            <StaticImage
                className=""
                style={{ width: 60, height: 60 }}
                objectFit=""
                src="../images/map-small.png"
                objectPosition="0% 0%"
                alt="studiesAndMe"
                quality={100}
                placeholder="none"
            ></StaticImage>

            <div className="ps-4 mb-0 h-100 d-flex flex-column justify-content-center">
              <h4 className="fw-bold" style={{ fontSize: '1.5rem' }}>
                {t('joinTheStudyAtYourConvenience.city1')}, {t('joinTheStudyAtYourConvenience.zipCode1')}
              </h4>
              <p className="">{t('joinTheStudyAtYourConvenience.states1')}</p>
            </div>
          </div>
        </div>

        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        <div className="col-12 col-md-4 d-none d-sm-block mb-4">
          <div
            className="p-3 bg-white h-100"
            style={{ borderRadius: '0.625rem', boxShadow: '0px 2px 6px 0px rgba(18, 21, 51, 0.25)' }}
          >
            <h5 className="mb-3 text-black">{t('joinTheStudyAtYourConvenience.city1')}</h5>
            <StaticImage
              className="w-100 d-md-block"
              objectFit="fill"
              src="../images/bellaire.png"
              objectPosition="0% 0%"
              alt="studiesAndMe"
              quality={100}
              placeholder="none"
            ></StaticImage>
            <div className="mt-3 text-black ">
              <p>
                {t('joinTheStudyAtYourConvenience.states1')}, {t('joinTheStudyAtYourConvenience.zipCode1')}
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 d-none d-sm-block mb-4">
          <div
            className="p-3 h-100 bg-white h-100"
            style={{ borderRadius: '0.625rem', boxShadow: '0px 2px 6px 0px rgba(18, 21, 51, 0.25)' }}
          >
            <h5 className="mb-3 text-black">{t('joinTheStudyAtYourConvenience.city2')}</h5>
            <StaticImage
              className="w-100 d-md-block"
              objectFit="fill"
              src="../images/duncansville.png"
              objectPosition="0% 0%"
              alt="studiesAndMe"
              quality={100}
              placeholder="none"
            ></StaticImage>
            <div className="mt-3 text-black">
              <p>
                {t('joinTheStudyAtYourConvenience.states2')}, {t('joinTheStudyAtYourConvenience.zipCode2')}
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 d-none d-sm-block mb-4">
          <div
            className="p-3 bg-white h-100"
            style={{ borderRadius: '0.625rem', boxShadow: '0px 2px 6px 0px rgba(18, 21, 51, 0.25)' }}
          >
            <h5 className="mb-3 text-black">{t('joinTheStudyAtYourConvenience.city3')}</h5>
            <StaticImage
              className="w-100 d-md-block"
              objectFit="fill"
              src="../images/kansas-city.png"
              objectPosition="0% 0%"
              alt="studiesAndMe"
              quality={100}
              placeholder="none"
            ></StaticImage>
            <div className="mt-3 text-black">
              <p>
                {t('joinTheStudyAtYourConvenience.states3')}, {t('joinTheStudyAtYourConvenience.zipCode3')}
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 d-none d-sm-block mb-4">
          <div
              className="p-3 bg-white h-100"
              style={{ borderRadius: '0.625rem', boxShadow: '0px 2px 6px 0px rgba(18, 21, 51, 0.25)' }}
          >
            <h5 className="mb-3 text-black">{t('joinTheStudyAtYourConvenience.city4')}</h5>
            <StaticImage
                className="w-100 d-md-block"
                objectFit="fill"
                src="../images/la-jolla.png"
                objectPosition="0% 0%"
                alt="studiesAndMe"
                quality={100}
                placeholder="none"
            ></StaticImage>
            <div className="mt-3 text-black">
              <p>
                {t('joinTheStudyAtYourConvenience.states4')}, {t('joinTheStudyAtYourConvenience.zipCode4')}
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 d-none d-sm-block mb-4">
          <div
            className="p-3 bg-white h-100"
            style={{ borderRadius: '0.625rem', boxShadow: '0px 2px 6px 0px rgba(18, 21, 51, 0.25)' }}
          >
            <h5 className="mb-3 text-black">{t('joinTheStudyAtYourConvenience.city5')}</h5>
            <StaticImage
              className="w-100 d-md-block"
              objectFit="fill"
              src="../images/la-mesa.png"
              objectPosition="0% 0%"
              alt="studiesAndMe"
              quality={100}
              placeholder="none"
            ></StaticImage>
            <div className="mt-3 text-black">
              <p>
                {t('joinTheStudyAtYourConvenience.states4')}, {t('joinTheStudyAtYourConvenience.zipCode5')}
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 d-none d-sm-block mb-4">
          <div
            className="p-3 bg-white h-100"
            style={{ borderRadius: '0.625rem', boxShadow: '0px 2px 6px 0px rgba(18, 21, 51, 0.25)' }}
          >
            <h5 className="mb-3 text-black">{t('joinTheStudyAtYourConvenience.city6')}</h5>
            <StaticImage
              className="w-100 d-md-block"
              objectFit="fill"
              src="../images/miami.png"
              objectPosition="0% 0%"
              alt="studiesAndMe"
              quality={100}
              placeholder="none"
            ></StaticImage>
            <div className="mt-3 text-black">
              <p>
                {t('joinTheStudyAtYourConvenience.states5')}, {t('joinTheStudyAtYourConvenience.zipCode6')}
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 d-none d-sm-block mb-4">
          <div
              className="p-3 bg-white h-100"
              style={{ borderRadius: '0.625rem', boxShadow: '0px 2px 6px 0px rgba(18, 21, 51, 0.25)' }}
          >
            <h5 className="mb-3 text-black">{t('joinTheStudyAtYourConvenience.city7')}</h5>
            <StaticImage
                className="w-100 d-md-block"
                objectFit="fill"
                src="../images/mobile.png"
                objectPosition="0% 0%"
                alt="studiesAndMe"
                quality={100}
                placeholder="none"
            ></StaticImage>
            <div className="mt-3 text-black">
              <p>
                {t('joinTheStudyAtYourConvenience.states7')}, {t('joinTheStudyAtYourConvenience.zipCode7')}
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 d-none d-sm-block mb-4">
          <div
              className="p-3 bg-white h-100"
              style={{ borderRadius: '0.625rem', boxShadow: '0px 2px 6px 0px rgba(18, 21, 51, 0.25)' }}
          >
            <h5 className="mb-3 text-black">{t('joinTheStudyAtYourConvenience.city8')}</h5>
            <StaticImage
                className="w-100 d-md-block"
                objectFit="fill"
                src="../images/morehead-city.png"
                objectPosition="0% 0%"
                alt="studiesAndMe"
                quality={100}
                placeholder="none"
            ></StaticImage>
            <div className="mt-3 text-black">
              <p>
                {t('joinTheStudyAtYourConvenience.states6')}, {t('joinTheStudyAtYourConvenience.zipCode8')}
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 d-none d-sm-block mb-4">
          <div
              className="p-3 bg-white h-100"
              style={{ borderRadius: '0.625rem', boxShadow: '0px 2px 6px 0px rgba(18, 21, 51, 0.25)' }}
          >
            <h5 className="mb-3 text-black">{t('joinTheStudyAtYourConvenience.city9')}</h5>
            <StaticImage
                className="w-100 d-md-block"
                objectFit="fill"
                src="../images/the-villages.png"
                objectPosition="0% 0%"
                alt="studiesAndMe"
                quality={100}
                placeholder="none"
            ></StaticImage>
            <div className="mt-3 text-black">
              <p>
                {t('joinTheStudyAtYourConvenience.states5')}, {t('joinTheStudyAtYourConvenience.zipCode9')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 px-0 pt-6 pb-6 d-flex justify-content-center">
        <Button
          onClick={clickker}
          type="primary"
          className="px-3 py-3 px-md-5"
          label={'joinTheStudyAtYourConvenience.signupButton'}
        ></Button>
      </div>
    </>
  )
}
export default ClinicsMap
