/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 31/08/2022.
 */
import React from 'react'
import { Button } from './Button'

//import CheckMark from '../assets/check-mark.svg'
import CheckMark from '../assets/check-mark.svg'
import StudyIcon from '../assets/study-icon.svg'
import CompensationIcon from '../assets/compensation-icon.svg'
import TreatmentIcon from '../assets/treatment-icon.svg'

import { useTranslation } from 'gatsby-plugin-react-i18next'

export const WhatInItForYou = ({ scrollToSignup }) => {
  const { t } = useTranslation()

  const clickker = () => {
    scrollToSignup()
  }

  return (
    <div className="container">
      <div className="row my-md-5" style={{}}>
        <div className="col-12 col-md-6 ">
          <h4 className="head-color mb-md-5 mt-0 mt-md-4  ">{t('whatsInItForYou.title')}</h4>
        </div>

        <div className="col-12 col-md-6">
          <p className="mb-5 mt-0 mt-md-4  ">{t('whatsInItForYou.body')}</p>
        </div>
      </div>
      <div className="row" style={{}}>
        <div className="col-12 col-md-4 mb-7">
          <div className="d-flex d-md-block align-items-center mb-3 ">
            <Icon>
              <StudyIcon className="icon-w-2 icon-w-md-2 img-fluid "></StudyIcon>
            </Icon>

            <h6 className="fw-bold mt-0"> {t('whatsInItForYou.text_headline')}</h6>
          </div>
          <p className="pe-6">{t('whatsInItForYou.text1')}</p>
        </div>

        <div className="col-12 col-md-4 mb-7">
          <div className="d-flex d-md-block mb-3 ">
            <Icon>
              <CompensationIcon className="icon-w-2 icon-w-md-2 img-fluid "></CompensationIcon>
            </Icon>

            <h6 className="fw-bold mt-2">{t('whatsInItForYou.text_headline2')}</h6>
          </div>
          <p className="pe-6">{t('whatsInItForYou.text2')}</p>
        </div>
        <div className="col-12 col-md-4">
          <div className="d-flex d-md-block mb-3 ">
            <Icon>
              <TreatmentIcon className="icon-w-2 icon-w-md-2 img-fluid "></TreatmentIcon>
            </Icon>
            <h6 className="fw-bold mt-2">{t('whatsInItForYou.text_headline3')}</h6>
          </div>
          <p className="pe-6">{t('whatsInItForYou.text3')}</p>
        </div>
      </div>
      <div className="col-12 px-0 pt-6 pb-6 d-flex justify-content-center">
        <Button
            onClick={clickker}
            type="primary"
            className="px-3 py-3 px-md-6"
            label={'whatsInItForYou.signupButton'}
        ></Button>
      </div>
    </div>
  )
}

const Icon = ({ children }) => {
  return <div className="pb-md-4 pe-3">{children}</div>
}

const BulletItem = ({ txtKey }) => {
  const { t } = useTranslation()
  return (
    <div className="d-flex  mb-5">
      <div className="">
        <CheckMark style={{ marginTop: 4, width: '2rem', height: '2rem' }}></CheckMark>
      </div>
      <h5 className="ms-3 mt-2 h5-small">{t(txtKey)}</h5>
    </div>
  )
}
