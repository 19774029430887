/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04/10/2022.
 */
import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from '@reach/dialog'
import ConsentIcon from '../../assets/consent.svg'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

// include styles
import './consent-modal.scss'
import { signUpUser } from '../../service/SignUpUser'
import Spinner from '../spinner/Spinner'

type Props = { showValue?: { data: any; language: string } }

export const ConsentModal = ({ showValue }: Props) => {
  const [show, setShow] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  const contentRef = useRef()

  useEffect(() => {
    if (showValue) {
      setShow(true)
    }
  }, [showValue])

  const showModal = () => {
    setShow(true)
  }

  const hideModal = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
    setShow(false)
  }

  const approveClick = () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)

    const data = { ...showValue.data, acceptConsent: true }

    signUpUser(data, showValue.language).catch((error) => {
      console.log('error = ', error)
    })
  }
  const STUDY_NAME = 'Donatello'

  return (
    <div className="position-relative">
      {/*<button onClick={showModal}>Show Dialog</button>*/}

      <Dialog isOpen={show} className="position-relative rounded" aria-label="consent">
        <button
          className="position-absolute btn-close btn "
          role="button"
          tabIndex={1}
          onKeyPress={hideModal}
          style={{ top: 20, right: 30, zIndex: 110 }}
          onClick={hideModal}
        ></button>

        <div className="container-fluid px-0 pb-2 h-100" style={{}}>
          <div className="h-100 d-flex flex-column">
            <div className=""></div>

            <div className="px-6" data-body-scroll-lock-ignore="true" style={{ overflowY: 'scroll' }}>
              <div className="">
                <div className="my-5 d-flex justify-content-center">
                  <ConsentIcon className="mt-5"></ConsentIcon>
                </div>

                <h3 className="text-center ">
                  {/* Your privacy */}
                  {t('studyConsent.yourPrivacy')}

                  <br />
                  {/* is our priority */}
                  {t('studyConsent.ourPriority')}
                </h3>
                <p className="mt-5 text-center ">
                  {/* V1: Do you consent to Studies&Me's processing of personal and sensitive information about you, with the
                  purpose of assessing whether you may be eligible to participate in the
                  <span className="fw-bold">{STUDY_NAME}</span> study? */}
                  {/* V2: Studies&Me processes different categories of personal information about you in the different steps of our online recruitment for this clinical study.
                   You can read more specifically about all of the categories of the personal information processed and
                   the purpose(s) for such processing in our privacy policy here.  */}
                  <Trans
                    i18nKey="studyConsent.processDifferentCategories"
                    components={{
                      privacy: <a href="https://studiesandme.com/privacy/" target="_blank" rel="noopener noreferrer" />,
                    }}
                  ></Trans>
                  {/*{t('studyConsent.processDifferentCategories')}*/}
                  {/*<a href="https://studiesandme.com/privacy/" target="_blank" rel="noopener noreferrer">
                    {t('studyConsent.privacyPolicyLink')}
                  </a>*/}
                </p>

                <p className="mt-4 text-center">
                  {/* V1: Studies&Me will not share any of your sensitive personal information with any third parties, without
                  your explicit consent. */}
                  {/* V2: The purpose of this consent form is to obtain your explicit and prior consent to the processing of the sensitive personal information you provide in the following questionnaire for */}
                  {t('studyConsent.purposeOfConsent')}
                  <span className="fw-bold">{STUDY_NAME}</span>
                </p>

                <p className="mt-4 text-center p-small">
                  {/* V1: The purpose of this form is to obtain your explicit and prior consent to the processing of the
                  sensitive personal information you provide in the following questionnaire for the {STUDY_NAME} study. */}
                  {/* V2: Categories of personal information, purpose and recipient(s) */}
                  {t('studyConsent.categories')}
                </p>

                <p className="mt-3  p-small">
                  {/* V1: We will collect and process your full name, email address and phone number, as well as sensitive
                  personal information about your health, including your age, height, weight and medical history. */}
                  {/* V2: Your full name, email address and phone number will be collected and processed when you fill out the questionnaire.
                   The categories of sensitive personal information that will be processed about you when you fill out the questionnaire entail your health data, including your age,
                   height, weight and medical history. */}
                  {t('studyConsent.whatWeCollect')}
                </p>

                <p className="mt-3  p-small">
                  {/* V1: The purpose of processing such personal information about you is to assess whether you may be eligible
                  to participate in the <span className="fw-bold">{STUDY_NAME}</span> study. If we assess that you may
                  be eligible, we will disclose your name, telephone number and email address to the nearest study
                  clinic that will contact you with regards to the next phases of the study. */}
                  {/* V2: The purpose of the processing of such personal information about you is to assess whether you may be eligible to participate in STRIDES. */}
                  {t('studyConsent.purposeOfProcessing1')}
                  <span className="fw-bold">{STUDY_NAME}</span>
                  {/* . If we determine that you may be eligible to participate in STRIDES */}
                  {t('studyConsent.purposeOfProcessing2')}
                  <span className="fw-bold">{STUDY_NAME}</span>
                  {/* , we will share your name, telephone number and email address with the nearest study clinic that will contact you in connection with the next phases of the study. */}
                  {t('studyConsent.purposeOfProcessing3')}
                </p>

                <p className="mt-3 text-center fw-bold">
                  {/* V1: Please note that, depending on your jurisdiction, you may have data subject rights, including the
                  right to withdraw your consent at any time by sending an e-mail to privacy@studiesandme.com. You can
                  read more about your other data subject rights in our privacy policy.If you have any questions
                  regarding the processing of your personal information, please contact Studies&Me on
                  <a href="mailto:privacy@studiesandme.com" className="ms-1 text-black">
                    privacy@studiesandme.com.
                  </a> */}
                  {/* V2: Studies&Me does not share any of your sensitive personal information with any third parties.  */}
                  {t('studyConsent.noInformationSharing')}
                </p>

                <p className="mt-3 text-center p-small">
                  {/* V2: Your explicit consent */}
                  {t('studyConsent.yourExplicitConsent')}
                </p>

                <p className="mt-3 p-small">
                  {/* V2: Do you consent to Studies&Me's processing of personal information, including sensitive personal information,
                   about you for the purpose of assessing whether you may be eligible to participate in STRIDES */}
                  {t('studyConsent.doYouConsent1')}
                  <span className="fw-bold">{STUDY_NAME}</span>
                  {/* V2: , and to store such personal information to contact you in the future if we determine that you may be eligible for future clinical studies?  */}
                  {t('studyConsent.doYouConsent2')}
                </p>

                <p className="mt-3 p-small">
                  {/* V2: Please note that, depending on your jurisdiction, you may have data subject rights,
                   including the right to withdraw your consent at any time by sending an e-mail to privacy@studiesandme.com.
                   You can read more about your other data subject rights in our privacy policy here. */}
                  <Trans
                    i18nKey="studyConsent.importantNote"
                    components={{
                      privacy: <a href="https://studiesandme.com/privacy/" target="_blank" rel="noopener noreferrer" />,
                    }}
                  ></Trans>

                  {/*{t('studyConsent.importantNote')}
                  <a href="https://studiesandme.com/privacy/" target="_blank" rel="noopener noreferrer">
                    {t('studyConsent.privacyPolicyLink')}
                  </a>*/}
                </p>

                <p className="mt-4 mb-5  p-small"></p>
                {/*    <p className="mt-4 mb-5  p-small">
                  V1: Studies&Me A/S, business registration no.: 41600020, Hauser Pl. 20, 1. floor, DK-1127, Copenhagen
                  K ("Studies&Me") collects and uses (processes) your personal information for the purposes of clinical
                  study recruitment and pre-screening. V2: Studies&Me A/S, business registration no.: 41600020, Hauser
                  Pl. 20, 1. floor, DK-1127, Copenhagen K (\"Studies&Me\") collects and uses (processes) your personal
                  information for the purposes of clinical study recruitment and pre-screening.
                  {t('footer.reachOutHere')}
                </p>*/}
              </div>
            </div>
            {/* ---- FOOTER --------- */}
            <div ref={contentRef} className="pt-2 pb-md-2 px-2 border-top ios-bottom ">
              <button
                ref={contentRef}
                role="button"
                tabIndex={0}
                onKeyPress={approveClick}
                onClick={approveClick}
                className="btn btn-primary w-100 py-1 d-flex justify-content-center"
              >
                {isLoading ? (
                  <div className="mb-4 mt-2">
                    <Spinner size={4} style={{ color: 'white' }}></Spinner>
                  </div>
                ) : (
                  <p className="text-white py-2 ">{t('studyConsent.acceptAndContinue')}</p>
                )}
              </button>
            </div>
          </div>
        </div>

        {/*<p>It is your job to close this with state when the user clicks outside or presses escape.</p>
        <button onClick={hideModal}>Okay</button>*/}
      </Dialog>
    </div>
  )
}

const Button = ({ onClick, children }) => {
  return (
    <button onClick={onClick} className="btn btn-primary w-100 py-1">
      {children}
    </button>
  )
}

const DeclineButton = ({ onClick, children }) => {
  return (
    <button onClick={onClick} className="btn btn-secondary w-100 mt-3 py-3">
      {children}
    </button>
  )
}
