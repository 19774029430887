/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 02/09/2022.
 */
import React from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'
import { SignUpForm } from './signUpForm/SignUpForm'
import { useForm } from 'react-hook-form'

import CheckMark from '../assets/check-mark.svg'

export const WhoAreWeLookingFor = ({ signupRef }) => {
  const { t } = useTranslation()
  const formHook = useForm()
  const { handleSubmit } = formHook

  return (
    <div className="container py-7 ">
      <div className="row" style={{}}>
        <div className="col-12 col-md-6 d-flex align-items-center ">
          {/* <h6>Facts about the study</h6> */}
          {/* What you need to know */}
          <div className="">
            <div className="d-flex align-items-center flex-column flex-md-row ">
              <h4 className="ms-0 ms-md-3 mt-4 mt-md-2 text-center text-md-start">{t('whoAreWeLookingFor.title')}</h4>
            </div>
            <h5 className="ms-0 ms-md-3 mt-4 mt-md-2 text-center text-md-start">
              {t('whoAreWeLookingFor.subHeadline')}
            </h5>
            <div className="ps-0 ps-md-4 mt-5 mb-6 mb-md-0">
              <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
                <div className="mb-3 mt-0 mt-md-2 mb-md-0">
                  <CheckMark></CheckMark>
                </div>
                <h5 className="ms-3 mb-5 h5-small pt-2 text-center text-md-start"> {t('whoAreWeLookingFor.text1')}</h5>
              </div>

              <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
                <div className="mb-3 mb-md-0">
                  <CheckMark></CheckMark>
                </div>
                <h5 className="ms-md-3 mb-5 h5-small text-center text-md-start">{t('whoAreWeLookingFor.text2')}</h5>
              </div>

              <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
                <div className="mb-3 mb-md-0">
                  <CheckMark></CheckMark>
                </div>
                <h5 className="ms-3 mb-5  h5-small text-center text-md-start">{t('whoAreWeLookingFor.text3')}</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-0 col-md-1"></div>
        <div className="col-12 col-md-5 d-flex px-0">
          <div
            ref={signupRef}
            className=" p-3 "
            style={{ background: '#FFFFFF', boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.1)' }}
          >
            <div className="mt-3 mb-3">
              <h5 className="mb-2 mt-1 fw-bold">{t('form.headline')}</h5>
              <p className="mb-3">{t('form.subHeadline')}</p>
            </div>

            <SignUpForm formHook={formHook} handleSubmit={handleSubmit}></SignUpForm>
          </div>
        </div>
      </div>
    </div>
  )
}
