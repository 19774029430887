/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/06/2023.
 */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import ClockIcon from '../assets/clock.svg'
import { Button } from './Button'

const ProcessOfSigningUp = ({ scrollToSignup }) => {
  const { t } = useTranslation()

  const clickker = () => {
    scrollToSignup()
  }

  return (
    <div className="container my-md-7 mt-0 mb-5">
      <div className="row" style={{}}>
        <div className="col-12 my-md-7 mb-6 mt-5 text-center text-md-start px-6 px-md-0">
          <h4>{t('howToParticipate.headline')}</h4>
        </div>
      </div>
      <div className="row" style={{}}>
        <div className="col-12 col-md-4 pe-md-6 px-4 ">
          <StaticImage
            className="w-75 mx-auto"
            src="../images/process_001.png"
            objectPosition="0% 0%"
            alt="studiesAndMe"
            quality={100}
            placeholder="none"
          ></StaticImage>

          <div className="">
            <h5 className="fw-bold mt-5 text-center text-md-start ">{t('howToParticipate.subHeadline1')}</h5>
            <Icon txtKey="howToParticipate.watchIcon"></Icon>
            <p className="text-center text-md-start mb-4 px-5 px-md-0">{t('howToParticipate.text1')}</p>
          </div>
        </div>
        <div className="col-12 col-md-4 px-5 mt-3 mb-3 mt-md-0 px-md-4 d-flex align-items-start flex-column-reverse flex-md-column">
          <div className="mt-5 mt-md-0 mb-md-4">
            <h5 className="fw-bold text-center text-md-start ">{t('howToParticipate.subHeadline2')}</h5>
            <Icon txtKey="howToParticipate.watchIcon2"></Icon>
            <p className="text-center  text-md-start">{t('howToParticipate.text2')}</p>
          </div>
          <StaticImage
            className="w-75 mx-auto"
            objectFit=""
            src="../images/process_002.png"
            objectPosition="0% 0%"
            alt="studiesAndMe"
            quality={100}
            placeholder="none"
          ></StaticImage>
        </div>
        <div className="col-12 mt-6 mt-md-0 col-md-4 pe-md-6 d-flex flex-column align-items-center align-items-md-start">
          <StaticImage
            className="w-75 mx-auto"
            objectFit=""
            src="../images/process_003.png"
            objectPosition="0% 0%"
            alt="studiesAndMe"
            quality={100}
            placeholder="none"
          ></StaticImage>
          <div className="">
            <h5 className="fw-bold mt-5 text-center text-md-start  ">{t('howToParticipate.subHeadline3')}</h5>
            <p className="mt-3 mb-4 text-center text-md-start">{t('howToParticipate.text3')}</p>
          </div>
        </div>
      </div>
      <div className="col-12 px-0 pt-6 pb-6 d-flex justify-content-center">
        <Button
          onClick={clickker}
          type="primary"
          className="px-3 py-3 px-md-6"
          label={'howToParticipate.signupButton'}
        ></Button>
      </div>
    </div>
  )
}

const Icon = ({ txtKey }) => {
  const { t } = useTranslation()
  return (
    <div className="my-2 d-flex justify-content-center justify-content-md-start ">
      <div className="">
        <ClockIcon></ClockIcon>
      </div>

      <p className="ms-2 p-small" style={{ marginTop: 6 }}>
        {t(txtKey)}
      </p>
    </div>
  )
}

export default ProcessOfSigningUp
